import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from 'react-phone-input-2'

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { toastAlert } from '../../lib/toastAlert'

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from '../../lib/isEmpty';

import { addCreator, EditCreator } from '../../actions/creator';

import config from '../../actions/config';

var baseUrl = config.baseUrl;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}


const initialFormValue = {
  'creatorname': "",
  'coverimage': null,
  'profileimage': null,
  'creatoraddress':""
}

const useStyles = makeStyles(styles);

export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();
  const [toched, setToched] = useState({});

  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [showcoverimage, setshowcoverimage] = useState("");
  const [showprofileimage, setshowprofileimage] = useState("");

  const { creatorid } = useParams();

  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
  }

    const {
      creatorname,
      coverimage,
      profileimage,
      creatoraddress
    } = formValue

    useEffect(() => {
      getCreator();
    }, []);

    const getCreator = async (search = {}) => {
      var res = await EditCreator({id:creatorid});
      setFormValue(res.result.data);

      var coverimage = baseUrl+"/creator/"+res.result.data.coverimage;
      var profileimage = baseUrl+"/creator/"+res.result.data.profileimage;

      setshowcoverimage(coverimage)
      setshowprofileimage(profileimage)
  }

  const handleFormSubmit = async (e) => {
    
    e.preventDefault();

    let formData = new FormData();
    formData.append('creatorname', formValue.creatorname);
    formData.append('coverimage', formValue.coverimage);
    formData.append('profileimage', formValue.profileimage);
    formData.append('creatoraddress', formValue.creatoraddress);
    formData.append('id', creatorid);
    
    var data = await addCreator(formData);
    toastAlert('success', data.message, 'category');
    history.push("/feature-creator")
  }

    const onFileChange = async (e) => {
    if(e && e.target && e.target.files && e.target.files[0]){
     
      let formData = { ...formValue, ...{ [e.target.id]: e.target.files[0] } }
      setFormValue(formData);
      var img = URL.createObjectURL(e.target.files[0]);
      if(e.target.id=="coverimage"){
        setshowcoverimage(img);
      }
      if(e.target.id=="profileimage"){
        setshowprofileimage(img);
      }
    }
  };

  let formdata = {};

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Add</h4>
                {/* <p className={classes.cardCategoryWhite}>Create a new user</p> */}
              </CardHeader>
              <CardBody>
                <GridContainer>                 
                 <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      onChange={onChange}
                      id="creatorname"
                      labelText="Creator name"
                      value={creatorname}
                      value={creatorname}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem> 
                </GridContainer>

                <GridContainer>     
                <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      onChange={onChange}
                      id="creatoraddress"
                      labelText="Creator address"
                      value={creatoraddress}
                      value={creatoraddress}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem> 
                </GridContainer>

                <GridContainer>                 
                 <GridItem xs={12} sm={12} md={3}>
                   <lable>Cover image</lable>
                    <input 
                      type="file" 
                      name="coverimage" 
                      id="coverimage" 
                      onChange={onFileChange}
                    />
                  </GridItem> 
                  {coverimage && coverimage !="" &&
                    <img width="100px" height="100px" src={showcoverimage}/>
                  }
                </GridContainer>

                <GridContainer>                 
                 <GridItem xs={12} sm={12} md={3}>
                   <lable>Profile image</lable>
                    <input 
                    type="file" 
                    name="profileimage" 
                    id="profileimage" 
                    onChange={onFileChange}
                    />
                  </GridItem> 
                  {profileimage && profileimage !="" && 
                    <img width="100px" height="100px" src={showprofileimage}/>
                  }
                </GridContainer>
                
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">Add</Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>       
      </GridContainer>
    </div>
  );
}
