
import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from "react-redux";

// import components
import Admin from "./components/layouts/Admin";
import ConditionRoute from './components/Route/ConditionRoute';
import UsersList from './pages/UsersList/UsersList';
import Usersedit from './pages/UsersList/useredit';
import LoginHistory from './pages/LoginHistory/loginHistory';

import EditProfile from './pages/UserProfile/EditProfile';
import securityType from './pages/Settings/securityType';
import EmailTemplate from './pages/Emailtemplate/emailList';
import EmailTemplateUpdate from './pages/Emailtemplate/updateTemplate';


import { AdminList, AdminAdd, AdminEdit } from './pages/Admin'
import { CurrencyList, CurrencyAdd, CurrencyEdit } from './pages/CurrencyManagement';
import { TradeHistory, WithdrawList, FundTansferHistory } from './pages/Report';
import supportView from './pages/Support/supportView';

import FaqAdd from './pages/Faq/faqAdd';
import FaqList from './pages/Faq/faqList';
import FaqUpdate from './pages/Faq/faqUpdate';

// import pages
import LoginPage from './pages/LoginPage/Login';
import ForgotPage from './pages/ForgotPage/ForgotPassword';
import changePassword from './pages/ForgotPage/changePassword';
import Dashboard from './pages/Dashboard/Dashboard';
import UserProfile from './pages/UserProfile/UserProfile'
import CategoryList from './pages/Category/CategoryList'
import categoryEdit from './pages/Category/categoryedit'
import categoryadd from './pages/Category/categoryadd'

import CreatorList from './pages/FeatureCreator/List';
import CreatorAdd from './pages/FeatureCreator/add';
import CreatorEdit from './pages/FeatureCreator/edit';
//Bid
import Bidpage from "./pages/Bids/Bids"

//Token 
import TokenList from "./pages/Token/Tokenlist"
import TokenView from "./pages/Token/Tokenview"
import TokenSold from "./pages/Token/TokenSold"

// community
import CommunitycategoryList from './community/Category/CategoryList'
import CommunitycategoryEdit from './community/Category/categoryedit'
import Communitycategoryadd from './community/Category/categoryadd'

import CommunityUserList from './community/Category/CommunityUserList'

//cms pagess
import CmsList from "./pages/Cms/Cmslist"
import Cmsauction from "./pages/Cms/Cmsauction"
import AddCMs from "./pages/Cms/Cmsadd"
import EditCMs from "./pages/Cms/cmsedit"

import Cms from "./pages/cmstermspolicy/cms"

import CmsEdit from "./pages/cmstermspolicy/cmsedit"
// End community

import Adminfees from './pages/Adminfees/List';
import ReportList from './pages/ReportList/List';

import SettingList from './pages/Settings/Setting'
import verificationlist from './pages/request/verificationlist'
import store from './store';

import "assets/css/material-dashboard-react.css?v=1.9.0";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import LoginHistory from "../deepliquidity_exchange_server/modals/loginHistory";

import globalStyle from './style/global.style';

// import action 
import { decodeJwt } from './actions/jsonWebToken'

const history = createBrowserHistory();

const App = () => {
    const { isAuth } = store.getState().currentUser;

    useEffect(() => {
        if (isAuth != true && localStorage.admin_token) {
            decodeJwt(localStorage.admin_token, store.dispatch)
        }
    }, [])

    return (
        <Provider store={store} >
            <ThemeProvider theme={globalStyle}>
                <ToastContainer />
                <Router history={history} basename={"/"}>
                    <Switch>

                        {/* Auth Route */}
                        <ConditionRoute path="/login" component={LoginPage} type={"auth"} />
                        <ConditionRoute path="/forgot" component={ForgotPage} type={"auth"} />
                        <ConditionRoute path="/changepassword/:authToken" component={changePassword} type={"auth"} />

                        {/* <ConditionRoute path="/changepassword" component={changePassword} type={"public"} /> */}

                        {/* Auth Route */}

                        {/* Private Route */}
                        <ConditionRoute path="/dashboard" component={Dashboard} layout={Admin} type={"private"} store={store} />

                        {/* Admin */}
                        <ConditionRoute exact path="/categorylist" component={CategoryList} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/verificationlist" component={verificationlist} layout={Admin} type={"private"} />

                        <ConditionRoute exact path="/addcategory" component={categoryadd} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/categoryedit/:userId" component={categoryEdit} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/list" component={AdminList} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/add" component={AdminAdd} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/eidt/:userId" component={AdminEdit} layout={Admin} type={"private"} />

                        <ConditionRoute exact path="/setting" component={SettingList} layout={Admin} type={"private"} />

                        {/* Token  */}

                        <ConditionRoute exact path="/TokenList" component={TokenList} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/TokenSold" component={TokenSold} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/TokenView/:userId" component={TokenView} layout={Admin} type={"private"} />



                        {/* Biddd */}
                        <ConditionRoute exact path="/bidlist" component={Bidpage} layout={Admin} type={"private"} />

                        {/* Cms */}


                        <ConditionRoute exact path="/CmsList" component={CmsList} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/Addcms" component={AddCMs} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/Editcms/:currencyId" component={EditCMs} layout={Admin} type={"private"} />
                        <ConditionRoute path="/Cmsauction" component={Cmsauction} layout={Admin} type={"private"} />

                        <ConditionRoute path="/cms" component={Cms} layout={Admin} type={"private"} />
                        <ConditionRoute path="/cmsedit/:cmsId" component={CmsEdit} layout={Admin} type={"private"} />

                        {/* Admin */}

                        <ConditionRoute path="/user" component={UserProfile} layout={Admin} type={"private"} />
                        <ConditionRoute path="/userList" component={UsersList} layout={Admin} type={"private"} />
                        <ConditionRoute path="/useredit/:userId" component={Usersedit} layout={Admin} type={"private"} />

                        {/* <ConditionRoute path="/support" component={support} layout={Admin} type={"private"} /> */}
                        <ConditionRoute path="/supportView/:id" component={supportView} layout={Admin} type={"private"} />

                        <ConditionRoute path="/currency" component={CurrencyList} layout={Admin} type={"private"} />
                        <ConditionRoute path="/addCurrency" component={CurrencyAdd} layout={Admin} type={"private"} />
                        <ConditionRoute path="/updateCurrency/:currencyId" component={CurrencyEdit} layout={Admin} type={"private"} />

                        <ConditionRoute path="/addFaq" component={FaqAdd} layout={Admin} type={"private"} />
                        <ConditionRoute path="/Faq" component={FaqList} layout={Admin} type={"private"} />
                        <ConditionRoute path="/faqUpdate/:faqId" component={FaqUpdate} layout={Admin} type={"private"} />

                        <ConditionRoute path="/loginhistory" component={LoginHistory} layout={Admin} type={"private"} />


                        <ConditionRoute path="/editprofile" component={EditProfile} layout={Admin} type={"private"} />
                        <ConditionRoute path="/emailTemplate" component={EmailTemplate} layout={Admin} type={"private"} />
                        <ConditionRoute path="/emailUpdatecms/:cmsId" component={EmailTemplateUpdate} layout={Admin} type={"private"} />


                        {/* Report */}
                        <ConditionRoute path="/trade-history" component={TradeHistory} layout={Admin} type={"private"} />
                        <ConditionRoute path="/withdraw" component={WithdrawList} layout={Admin} type={"private"} />
                        <ConditionRoute path="/fund-transfer-history" component={FundTansferHistory} layout={Admin} type={"private"} />
                        {/* Report */}


                        <ConditionRoute exact path="/Communitycategorylist" component={CommunitycategoryList} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/Communityaddcategory" component={Communitycategoryadd} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/Communitycategoryedit/:userId" component={CommunitycategoryEdit} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/CommunityUserList" component={CommunityUserList} layout={Admin} type={"private"} />

                        <ConditionRoute exact path="/feature-creator" component={CreatorList} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/add-creator" component={CreatorAdd} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/edit-creator/:creatorid" component={CreatorEdit} layout={Admin} type={"private"} />

                        <ConditionRoute exact path="/adminfees" component={Adminfees} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/report-list" component={ReportList} layout={Admin} type={"private"} />

                        {/* Public Route */}

                        {/* Public Route */}
                        {/* <Route path="/login" component={LoginPage} /> */}
                        <Redirect from="/" to="/login" />

                        {/* <Route path="/admin" component={Admin} /> */}




                    </Switch>
                </Router>
            </ThemeProvider>

        </Provider>
    )

}

export default App;