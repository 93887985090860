import axios from 'axios';

// import action
import { decodeJwt } from './jsonWebToken';

// import lib
import config from '../actions/config';
import setAuthToken from '../lib/setAuthToken';


export const getAllFees = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/getAllFees`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getReport = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/get-report`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}