// @material-ui/icons
import {
  Dashboard,
  Person,
  LibraryBooks,
  List,
  Equalizer,
  Timeline,
  Settings,
  Money,
  History,
  Email,
  Help,
  LiveHelp,
  AccountBalanceWallet,
  AccountBalance,
  ExitToApp,
  ArrowForwardIcon

} from "@material-ui/icons";

import AdjustIcon from '@material-ui/icons/Adjust';

const dashboardRoutes = [
  {
    id: "login",
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "forgot",
    path: "/forgot",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "change-password",
    path: "/changepassword/:authToken",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "security",
    path: "/securityType",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "edit-profile",
    path: "/editprofile",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },

  /* Dashboard */
  {
    id: "dashboard",
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    isSideMenu: true,
    isEdit: false,
    isRestricted: 'single'
  },

  /*Admin  */
  {
    id: "admin",
    path: "/list",
    name: "Admin Management",
    icon: List,
    isSideMenu: true,
    isEdit: true,
    isRestricted: 'multi',
    child: [
      {
        id: 'add',
        path: "/add",
      },
      {
        id: 'edit',
        path: "/edit/:userId",
      }
    ]
  },

  /* User */
  {
    path: "#",
    id: "Settings",
    name: "Settings Details",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Settings",
        path: "/setting",
        icon: Person,
      }

    ]
  },


  {
    path: "#",
    id: "category",
    name: "Category Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Category Lists",
        path: "/categorylist",
        icon: Person,
      },

    ]
  },

  {
    path: "#",
    id: "Bid",
    name: "Bid ",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Bid Lists",
        path: "/bidlist",
        icon: Person,
      },

    ]
  },
  {
    path: "#",
    id: "users",
    name: "User Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "User Lists",
        path: "/userList",
        icon: Person,
      },

    ]
  },

  {
    path: "#",
    id: "token",
    name: "Token Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Tokens List",
        path: "/TokenList",
        icon: Person,
      },{
        name: "Tokens Sold",
        path: "/TokenSold",
        icon: Person,
      }

    ]
  },
  {
    path: "#",
    id: "request",
    name: "Verification",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Email Verification",
        path: "/verificationlist",
        icon: Person,
      },

    ]
  },


  // {
  //   path: "#",
  //   id: "emailtemplate",
  //   name: "Email Template",
  //   icon: List,
  //   type: "multi",
  //   isEdit: false,
  //   isSideMenu: true,
  //   child: [
  //     {
  //       name: "Email Template List",
  //       path: "/emailTemplate",
  //       icon: Person,
  //     }
  //   ]
  // },

  {
    path: "#",
    id: "adminfee",
    name: "Admin fees",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Admin fees",
        path: "/adminfees",
        icon: Person,
      }

    ]
  },
  {
    path: "#",
    id: "reportlist",
    name: "Report list",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Report list",
        path: "/report-list",
        icon: AdjustIcon,
      }

    ]
  },
];

export default dashboardRoutes;
