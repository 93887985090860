import axios from 'axios';

// import action
import { decodeJwt } from './jsonWebToken';

// import lib
import config from '../actions/config';
import setAuthToken from '../lib/setAuthToken';

import {
    SET_CURRENT_USER
} from '../constant';


// import constant
// import {
//     SET_CURRENT_USER
// } from '../constant';



export const getsinglecms = async (currencyId) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getsinglecms/` + currencyId,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.data
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const updateCms = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/updateCms`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const login = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/login`,
            data
        });

        localStorage.setItem('admin_token', respData.data.token);
        setAuthToken(respData.data.token)
        decodeJwt(respData.data.token, dispatch)

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}




export const getAllPairs = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getPairs`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

// Update Currency Id 
// export const updateCurrency = async (data,headers) => {
//     try {
//         let bodyFormData = new FormData();
//         bodyFormData.append('currencyName', data.currencyName);
//         bodyFormData.append('currencySymbol', data.currencySymbol);
//         bodyFormData.append('currencyType', data.currencyType);
//         bodyFormData.append('photo', data.photo);
//         bodyFormData.append('decimals', data.decimals);
//         bodyFormData.append('fee', data.fee);
//         bodyFormData.append('minimum', data.minimum);
//         bodyFormData.append('contractAddress',data.contractAddress);
//         bodyFormData.append('userId', data.userId);
//         var headersDefault = {
//             'Content-Type': 'multipart/form-data',
//             'Authorization': localStorage.admin_token
//         };
//         var finalHeaders = Object.assign(headersDefault, headers);


//         let respData = await axios({
//             'method': 'post',
//             'url': `${config.API_URL}/adminapi/updateCurrency`,
//             headers: finalHeaders,
//             data: bodyFormData
//         });

//     /* Merge defaults and options, without modifying defaults */
//         return {
//             loading: true,
//             result: respData.data.result
//         }

//     }
//     catch (err) {
//         return {
//             loading: false,
//             error: err
//         }
//     }
// }

export const addFaq = async (data) => {

    try {
        
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/addFaq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data.result
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const updateFaq = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/updateFaq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data.result
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const getBidslist = async (filterData, dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getBidslist`,
            params: filterData
        });
        return {
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }

    
}

export const getSoldtokenlist = async (filterData, dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getsoldtokenlist`,
            params: filterData
        });
        return {
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const gettokenlist = async (filterData, dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/gettokenlist`,
            params: filterData
        });
        return {
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getcatory = async (filterData, dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getcatory`,
            params: filterData
        });
        return {
            loading: false,
            result: respData.data

        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const gettokendata = async (id) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/gettokendata/` + id,
        });


        return {
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const deleteToken = async (id, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/deleteToken/` + id,
            'headers': {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            loading: false,
            userValue: respData
        }
    }
   
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getAllverifylist = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/getAllverifylist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const rejectreq = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/rejectreq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const acceptreq = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/acceptreq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getAllprohibited = async (data) => {
    try {

        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/prohibitedlist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

  export const addProhibited = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/addprohibited`,
            headers: {
                'Authorization': localStorage.admin_token
            },
           data
        });
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getsingleword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/singleprohibited`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
       return {
            loading: false,
            result: respData.data.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const updateWord = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/updateWord`,
            headers: {
                'Authorization': localStorage.admin_token
            },
           data
        });
       return {
            loading: false,
            result: respData.data.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const deleteWord = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/deleteword`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getAllcategory = async (data) => {
    try {

        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/categorylist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getAllCms = async (data) => {
    try {

        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/cmslist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getAllSetting = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/settinglist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const addcategory = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/addcategory`,
            headers: {
                'Authorization': localStorage.admin_token
            },
           data
        });
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const updatecategory = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/updatecategory`,
            headers: {
                'Authorization': localStorage.admin_token
            },
           data
        });
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getcategory = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/categorydetail`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
       return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getAllUsers = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getAllusers`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
       return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
//Get Currency id currency management module

export const getCurrencyId = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getCurrencyId?userid=` + data,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getFaqId = async (id) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getFaq/` + id,
            headers: {
                'Authorization': localStorage.admin_token
            },

        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getPairId = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getPairId?pairid=` + data,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getUser = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getUsers?userid=` + data,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const updateProfile = async (data) => {
    try {

        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/updateprofile`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const updateUser = async (data) => {
    try {

        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/updateUser`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const updateEmail = async (data) => {
    try {

        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/updateEmailTemplate`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const sendSubscribe = async (data) => {
    try {

        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/sendsubscribe`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const forgotPassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/forgotPassword`,
            // headers: {
            //     'Authorization': localStorage.admin_token
            // },
            data

        });

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const changePassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/resetPassword`,
            // headers: {
            //     'Authorization': localStorage.admin_token
            // },
            data

        });

        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getLoginHistory = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getLoginHistory`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getemaillist = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getemaillist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.data
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getFaqList = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getFaqList`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.data
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}






export const getAddressProof = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/proof/getAllAddress`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data

        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getEmailid = async (id) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getemailid/` + id,
            headers: {
                'Authorization': localStorage.admin_token
            },

        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getBankDetails = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/proof/getAllBank`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });


        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const UpdateStatus = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/proof/updateStatus`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
            // data = {
            //     id:"check",
            //     status:3,
            //     role:1
            //     }
        });


        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getadminUsers = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/adminprofile`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });

        return {
            loading: false,
            result: respData.data.data
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const get2faCode = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/get2faCode`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const update2faCode = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/update2faCode`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const disable2faCode = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/diabled2faCode`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const deletecategory = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/deletecategory`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const deleteFaq = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/deleteFaq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}



//Support page 
export const getSupportList = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/supportList`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

//Using post method only Support page Pass id using delete api  

export const deleteSupport = async (id) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/supportDelete/` + id,
            headers: {
                'Authorization': localStorage.admin_token
            },

        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getSupportId = async (id, dispatch) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getSupportId/` + id,
            'headers': {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            loading: false,
            userValue: respData.data.userValue
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const closeticket = async (id, dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API}/adminapi/closeticket/` + id,
            'headers': {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            loading: false,
            userValue: respData.data.userValue
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const savereply = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API}/adminapi/savereply`,
            'headers': {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const logout = (history) => {
    localStorage.removeItem('admin_token');
    history.push('/Login')
    setAuthToken('')
}

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const getSetting = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/setting`,
            'headers': {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const updateSetting = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/setting`,
            'headers': {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            status: 'success',
            loading: false,
            messages: respData.data.messages,
            result: respData.data.result,
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const changeStatus = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/change-status`,
            'headers': {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            status: 'success',
            loading: false,
            messages: respData.data.messages,
            result: respData.data.result,
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
            error: err.response.data.errors
        }
    }
}