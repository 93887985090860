const env = "prod"
let key = {};
if (env === "prod") {
   key = {
      baseUrl: "https://api.pikaso.market",
      frontUrl: "https://admin.pikaso.market/",
      API_URL: "https://api.pikaso.market/",
      singleContract: "0xd45086c7c3CF373e68610Bc36e5b77B8bc955aE2",
      multimintContract: "0x17c31Dc7dF24288915ea279a16398ae9738cE273",
   };
}
else if (env === "test") {
   key = {
      baseUrl: "https://pikaso.ceewen.xyz:9001",
      frontUrl: "https://pikaso.ceewen.xyz/",
      API_URL: "https://pikaso.ceewen.xyz:9001/",
      // baseUrl: "http://128.199.129.139:9001",
      // frontUrl: "http://128.199.129.139:9001/",
      // API_URL: "http://128.199.129.139:9001/",
      singleContract: "0xd45086c7c3CF373e68610Bc36e5b77B8bc955aE2",
      multimintContract: "0x17c31Dc7dF24288915ea279a16398ae9738cE273",
   };
} 
else {
   key = {
      baseUrl: "http://localhost:9001",
      API_URL: "http://localhost:2054",
      singleContract: "0x51CF782ce3B9FbF5D5be2Fc0B9905D22Fefbad35",
      multimintContract: "0xCE8F7Fc5c2c6Df0e26fCC4fd03D42bA48C6AFf38",
      frontUrl: "http://localhost:3000",
   };
}

export default key;