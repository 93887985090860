import axios from 'axios';

// import action
import { decodeJwt } from './jsonWebToken';

// import lib
import config from '../actions/config';
import setAuthToken from '../lib/setAuthToken';


export const addCreator = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/save-creator`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            message: respData.data.message
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getAllCreator = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/getAllCreator`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const EditCreator = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/edit-creator`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}